import crudActions from "@/store/templates/b-crud/actions";
import apiModule from "@/api/delivery-rule";
import { deliveryRule as schema } from "@/store/schema";
import { isNil } from "lodash";
import { denormalize } from "normalizr";
import { SET_MODEL } from "@/store/templates/b-crud/mutation-types";
import triggerGroupsModelTransformerFn from "@/store/modules/rule-trigger-groups/model-transformer";

export default {
  ...crudActions(
    apiModule,
    schema,
    state => ({
      id: state.model.id,
      name: state.model.name,
      action: state.model.action,
      isActive: state.model.isActive,
      isIgnorable: state.model.isIgnorable,
      isRejectable: state.model.isRejectable,
      rejectReason: isNil(state.model.rejectReason)
        ? null
        : state.model.rejectReason.id,
      triggers: triggerGroupsModelTransformerFn(state.ruleTriggerGroups.model)
    }),
    ({ commit, entities, result }) => {
      // Get the entity, using the schema's key as entity name.
      const entity = entities[schema.key][result];
      // Denormalize the entity
      const model = denormalize(entity, schema, entities);
      // Turn triggerGroups into their own variable.
      const triggerGroups = model.triggers;
      // Delete the trigger groups from the model.
      delete model.triggers;
      // Set the model.
      commit(SET_MODEL, model);
      // Set the trigger groups in a module of their own
      commit(`ruleTriggerGroups/${SET_MODEL}`, triggerGroups);
    }
  )
};
